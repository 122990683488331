.company-section {
  padding: 5px 10px;
  background-color: #f8f8f8;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  color: black;
  width: 100%;
  margin: 15px auto;
  margin-bottom: 0px;
}
.sign-up-input {
  min-width: 250px;
  margin: 10px !important;
}
.sign-up-input-full {
  max-width: 520px;
  margin: 10px !important;
}
.signup-section-title {
  max-width: 525px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.company-section-details {
  margin-left: 10px !important;
  margin-right: auto !important;
  max-width: 550px;
}
.company-action-button {
  border-radius: 12px !important;
  max-width: 80px;
  float: right;
  margin-right: 20px;
}
.subscribe-card {
  width: 500px;
  height: 80px;
  flex-direction: row;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border: 1px lightgray solid;
  margin-bottom: 20px;
}
.plan-price {
  font-size: 26px;
  font-weight: bold;
  margin-left: 5px;
}
.plan-actions-container {
  display: flex;
  width: 120px;
  justify-content: space-between;
  align-items: center;
}
.card-input-container {
  background-color: #fafafa;
  padding: 10px;
  border-radius: 8px;
  margin-top: 20px;
  width: 500px;
}
.subscription-details-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.subscribe-button {
  width: 500px;
  margin-top: 30px;
}
.grey-box-container {
  background-color: #f8f8f8;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.single-kpi-container {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.section-title {
  font-size: 22px;
  font-weight: 600;
  margin-right: 15px;
}
.stats-chart-container {
  margin: auto;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
