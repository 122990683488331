.table-header {
  background-color: #f7c456;
  padding: 10px;
}
.items-header-cell {
  display: flex;
  align-items: center;
}
.items-header-text {
  font-size: 13px;
  font-weight: 500;
}
.item-table-row {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 2px;
  border-width: 1px;
  border-color: #f1f4f5;
  border-style: solid;
}
.item-row-priority {
  font-weight: bold;
  text-align: center;
  font-size: 22px;
  line-height: 16px;
  margin-left: 25px;
}
.item-edit-actions-container {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 30px;
  margin-bottom: 20px;
}
.item-action-button {
  border-radius: 12px;
}
.empty-items {
  padding: 20vh;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.filter-button {
  padding: 0px;
  margin: 0px;
  height: 15px;
  justify-content: center;
  display: flex;
  align-items: center;
}
.subcategory-filter-row {
  cursor: pointer;
  width: 100%;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px lightgray solid;
}
.subcategory-filter-row-title {
  text-align: left;
  margin-right: 5px;
  max-width: 200px;
}
.items-filter-badge .ant-badge-count {
  border-radius: 4px;
  background-color: white;
  color: black;
  margin-left: 4px;
}
.filter-title {
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px lightgray solid;
  width: 116%;
  text-align: center;
  padding-bottom: 10px;
}
.loading-container {
  min-height: 85vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  backdrop-filter: blur(2px);
}
.select-container {
  width: 116%;
  max-height: 400px;
  overflow: scroll;
}
.item-skeleton {
  width: 100% !important;
  margin-top: 5px;
  min-height: 45px;
}
.ant-upload.ant-upload-select-picture-card {
  width: 150px;
  height: 150px;
  margin: 15px 8px;
}
.ant-upload-list-picture-card-container {
  width: 150px;
  height: 150px;
  margin: 15px 8px;
}
