.company-section {
  padding: 5px 10px;
  background-color: #f8f8f8;
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
  color: black;
  width: 100%;
  margin: 15px auto;
  margin-bottom: 0px;
}
.sign-up-input {
  min-width: 250px;
  margin: 10px !important;
}
.sign-up-input-full {
  width: 100%;
  margin: 10px !important;
}
.signup-section-title {
  max-width: 525px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.signup-section-details {
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 550px;
}
