*:focus {
  outline: none;
}

.task-comments {
  width: 100%;
  border: 1px solid black;
  padding: 10px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.task-comments .comments {
  max-height: 400px;
  overflow: scroll;
}

.task-comments .empty-list {
  height: 100%;
  display: flex;
  text-align: center;
  width: 100%;
  font-weight: 600;
  justify-content: center;
  align-items: center;
}

.task-comments .loader {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.task-comments .comment {
  margin-bottom: 10px;
  line-height: 16px;
}

.task-comments .comment .user-name {
  color: #f7c456;
  margin-right: 5px;
  font-weight: 600;
  font-size: 16px;
}

.task-comments .comment .comment-message {
  font-weight: 500;
}

.task-comments .input-container {
  width: 100%;
  display: flex;
  min-height: 40px;
  align-items: center;
  padding-left: 5px;
  border: 1px solid black;
}

.task-comments .input-container .textarea {
  font-family: inherit;
  font-size: inherit;
  padding: 1px 6px;
}

.task-comments .input-container .textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  line-height: 20px;
}

.task-comments .input-container .textarea[contenteditable]:empty::before {
  content: 'Write a comment...';
  color: gray;
}

.task-comments .input-container .post-btn {
  border-radius: 20px;
  height: 30px;
  margin-right: 10px;
}
