.users-columns-container {
	margin-top: 5px;
	height: 380px;
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-end;
}

.users-columns-container .user-col-container {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-end;
}

.users-columns-container .small-col {
	position: absolute;
	bottom: 0px;
}

.users-columns-container .shadow {
	filter: drop-shadow(0 0 0.2rem lightgrey);
}

.users-columns-container .profile-photo {
	width: 45px;
	height: 45px;
	border-radius: 23px;
	overflow: hidden;
	margin-bottom: 10px;
}

.profile-photo {
	width: 45px;
	height: 45px;
	object-fit: cover;
}

.chart-user-col {
	display: flex;
	height: 100%;
	width: 95px;
	border-top-left-radius: 22px;
	border-top-right-radius: 22px;
	align-items: center;
	padding-top: 10px;
	font-size: 14px;
	font-weight: 500;
	flex-direction: column;
	overflow: hidden;
}
