.quick-edit-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  text-align: left;
}
.quick-edit-input .input {
  height: 30px;
}
.quick-edit-input .close-button {
  padding: 0;
  width: 22px;
  margin-left: 15px;
}
.quick-edit-input .save-button {
  padding: 0;
  width: 22px;
  margin-left: 5px;
}
